import { Component, Vue } from "vue-property-decorator";
import PasswordModify from "@/store/password/modify";
import { ResetMailRequest } from "@/api/password/request";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component
export default class index extends Vue {
  // メールアドレス
  email = null as string | null;
  // ロゴ画像
  logoImagePath = null as string | null;

  get loading() {
    return Loading.isLoading;
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await PasswordModify.clearResponse();
  }

  async mounted() {
    const storage = BusinessServiceStorage.getSessionStorage();
    this.logoImagePath = storage.logoImagePath ? storage.logoImagePath : null;
  }

  /**
   * 送信ボタンのイベントハンドラー
   */
  async send() {
    await Flash.clear();
    await PasswordModify.resetMail({
      authEmail: this.email
    } as ResetMailRequest);
    if (PasswordModify.isSuccess) {
      await this.$router.push({ name: "forget-password-sendmail" });
    } else {
      await Flash.setErrorNow({
        message: PasswordModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }
}
