import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as Password from "@/api/password";
import { CommonResponse, isSuccess } from "@/api/response";
import {
  ResetCheckRequest,
  ResetMailRequest,
  ResetRequest
} from "@/api/password/request";

const MODULE_NAME = "password/modify";

/**
 * パスワード再設定APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Modify extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  // MutationActions
  @MutationAction
  async resetMail(resetMailRequest: ResetMailRequest) {
    const commonResponse = await Password.resetMail(resetMailRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async resetCheck(resetCheckRequest: ResetCheckRequest) {
    const commonResponse = await Password.resetCheck(resetCheckRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async reset(resetRequest: ResetRequest) {
    const commonResponse = await Password.reset(resetRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Modify);
